<template>
  <div class="article">
    <div class="article-body">
      <img class="article-cover" :src="cover"/>
      <div class="article-title">{{title}}</div>
      <div class="article-subtitle">{{subtitle}}</div>
      <div class="article-category">{{category}}</div>
      <div class="article-author">
        <span>作者：{{author}}</span>
        <span>发布：{{createdTime}}</span>
      </div>
      <div class="article-content" v-html="content"></div>
    </div>
  </div>
</template>

<script>
  import {mapActions, mapState, mapGetters} from "vuex";

  export default {
    name: "knowledge",
    data() {
      return {
        cover:"",
        title:"",
        subtitle:"",
        content:"",
        createdTime:"",
        author:"",
        category:""
      }
    },
    created() {
      let id = this.$route.query.id || "";
      this.getArticleById({id}).then(res => {
        this.cover = res.data.coverUrls[0]
        this.title = res.data.title
        this.subtitle = res.data.subtitle
        this.content = res.data.content
        this.createdTime = res.data.createdTime
        this.author = res.data.author
        let categoryList = []
        for(let item of res.data.articleCategoryDtoList){
          categoryList.push(item.name)
        }
        this.category = categoryList.join(" / ")
      })
    },
    methods: {
      ...mapActions({
        getArticleById: "article/getArticleById",
      }),
    }
  }
</script>

<style scoped lang="scss">
  @media screen and (min-width: $minDeviceWidth_pc) {
    @import "article_pc";
  }

  @media screen and (max-width: $minDeviceWidth_pc) {
    @import "article_mobile";
  }

  @font-face {
    font-family: 'PingFang-Bold';
    src: url('/static/fonts/PINGFANG_BOLD_0.ttf'); /* IE9 */
    src: url('/static/fonts/PINGFANG_BOLD_0.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PingFang-extralight';
    src: url('/static/fonts/PINGFANG_EXTRALIGHT_0.ttf'); /* IE9 */
    src: url('/static/fonts/PINGFANG_EXTRALIGHT_0.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PingFang-heavy';
    src: url('/static/fonts/PINGFANG_HEAVY_0.ttf'); /* IE9 */
    src: url('/static/fonts/PINGFANG_HEAVY_0.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PingFang-light';
    src: url('/static/fonts/PINGFANG_LIGHT_0.ttf'); /* IE9 */
    src: url('/static/fonts/PINGFANG_LIGHT_0.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PingFang-medium';
    src: url('/static/fonts/PINGFANG_MEDIUM_0.ttf'); /* IE9 */
    src: url('/static/fonts/PINGFANG_MEDIUM_0.ttf') format('truetype');
  }
  @font-face {
    font-family: 'PingFang-regular';
    src: url('/static/fonts/PINGFANG_REGULAR_0.ttf'); /* IE9 */
    src: url('/static/fonts/PINGFANG_REGULAR_0.ttf') format('truetype');
  }
  @font-face {
    font-family: 'hanzhenguangbiao';
    src: url('/static/fonts/hanzhenguangbiao.ttf'); /* IE9 */
    src: url('/static/fonts/hanzhenguangbiao.ttf') format('truetype');
  }
  @font-face {
    font-family: 'ruiboti';
    src: url('/static/fonts/ruiboti.ttf'); /* IE9 */
    src: url('/static/fonts/ruiboti.ttf') format('truetype');
  }
</style>
